<template>
  <div>
    <Actions>
      <DeliveryActions
        :searchFields="searchFields"
        @changeSearchParams="changeSearchParams"
      />
      <ExpandToggles v-if="data.items && itemsReady" :items="$refs.items" />
      <ClearSearch @clearSearchResults="clearSearchResults" />
    </Actions>
    <Table :pageModel="pageModel" @changePage="changePage" :items="data.items">
      <TableHead
        slot="head"
        :searchFields="searchFields"
        @changeSearchParams="changeSearchParams"
      ></TableHead>
      <TableItem
        slot="body"
        v-for="row in data.items"
        v-bind:key="row.id"
        :row="row"
        ref="items"
      ></TableItem>
    </Table>
  </div>
</template>
<script>
import userInfo from "@/mixins/userInfo";
import { contributionApiClient, deliveryApiClient } from "@/mixins/apiClients";
import TableItem from "./TableItem.vue";
import TableHead from "./TableHead.vue";
import DeliveryActions from "./Actions.vue";
import Table from "@/components/Table/Table.vue";
import Actions from "@/components/Table/Actions/Actions.vue";
import ClearSearch from "@/components/Table/Actions/ClearSearch.vue";
import ExpandToggles from "@/components/Table/Expand/Toggles";
import msgUtils from "@/mixins/msgUtils.js";
import AppConfig from "@/app-config";

export default {
  name: "DeliveryAllPage",
  mixins: [msgUtils, contributionApiClient, deliveryApiClient, userInfo],
  components: {
    TableItem,
    TableHead,
    Table,
    DeliveryActions,
    Actions,
    ClearSearch,
    ExpandToggles
  },
  props: {
    itemsPerPage: {
      type: Number,
      default: 10
    }
  },
  methods: {
    async onDataNeeded(pageModel, searchFields) {
      let emptyResponse = this.getEmptyPagedResponse();

      const searchId = this.$router.currentRoute.query.id;
      if (searchId) {
        const idSearchResponse = await this.deliveryApiClient.getById(searchId);
        this.data = {
          ...emptyResponse,
          items: [idSearchResponse]
        };
        return;
      }
      let query = this.deliveryApiClient
        .list()
        .page(pageModel.currentPage || 0)
        .perPage(pageModel.itemsPerPage || 0);
      for (let [key, value] of Object.entries(searchFields)) {
        if (value) query.param(key, value);
      }
      let response = null;
      try {
        response = await query.exec();
      } catch (e) {
        this.handleApiError(e);
      }

      for (const item of response.items) {
        item.isStreamProfileLocked = item.isStreamProfileLocked ? "Yes" : "No";
      }
      this.data = response ?? emptyResponse;

      this.pageModel.currentPage = this.data.page;
      this.pageModel.itemsPerPage = this.itemsPerPage;
      this.pageModel.totalPages = this.data.totalPages;
      this.pageModel.totalItems = this.data.totalItems;
      this.searchFields = searchFields;
      await this.getContributions();
    },

    async getContributions() {
      const contributions = {};
      for (const row of this.data.items) {
        const contrbId = row.contributionId;
        if (!Object.prototype.hasOwnProperty.call(contributions, contrbId)) {
          try {
            contributions[contrbId] = await this.contributionApiClient.getById(
              row.contributionId
            );
          } catch (e) {
            continue;
          }
        }
        if (Object.prototype.hasOwnProperty.call(contributions, contrbId)) {
          row.name = contributions[contrbId].name;
          row.start = contributions[contrbId].plannedStart;
          row.end = contributions[contrbId].plannedEnd;
          row.type = contributions[contrbId].contributionType;
          row.description = contributions[contrbId].description;
        }
      }
    },
    async changePage(pageModel) {
      this.pageModel = pageModel;
      await this.onDataNeeded(this.pageModel, this.searchFields);
    },
    async changeSearchParams(searchFields) {
      this.searchFields = searchFields;
      this.pageModel.currentPage = 0;
      await this.onDataNeeded(this.pageModel, this.searchFields);
    },
    async clearSearchResults() {
      if (this.$router.currentRoute.query.id) {
        this.$router.push({ path: "/delivery/all" });
      }
      this.searchFields = {};
      this.onDataNeeded(this.pageModel, this.searchFields);
    },
    startPeriodicFetch() {
      this.onDataNeeded(this.pageModel, this.searchFields);
      this.intervalId = setInterval(() => {
        this.onDataNeeded(this.pageModel, this.searchFields);
      }, AppConfig.instance.config.API_OPTIONS.ROW_UDPATE_THRESHOLD); // 30 seconds
    },
    stopPeriodicFetch() {
      if (this.intervalId) {
        clearInterval(this.intervalId);
        this.intervalId = null;
      }
    }
  },
  async created() {
    await this.onDataNeeded(this.pageModel, this.searchFields);
  },
  watch: {
    "$route.query.id"(newId) {
      if (newId) {
        this.onDataNeeded(this.pageModel, this.searchFields);
      }
    },
    "data.items": {
      immediate: true,
      handler() {
        this.$nextTick(() => {
          if (this.$refs.items && this.$refs.items.length > 0) {
            this.itemsReady = true;
          }
        });
      }
    }
  },
  mounted() {
    this.startPeriodicFetch();
  },
  beforeDestroy() {
    this.stopPeriodicFetch();
  },
  data() {
    return {
      selectedDeliveriesForDetails: [],
      intervalId: null,
      contributionType: "",
      searchFields: {},
      pageModel: {
        currentPage: 0,
        itemsPerPage: this.itemsPerPage,
        totalPages: 0,
        totalItems: 0
      },
      data: {
        items: null
      },
      itemsReady: false
    };
  }
};
</script>
<style scoped></style>
